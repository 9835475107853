<template>
<div class="m-2">
  <b-form-textarea v-model="input"/>
  <div class="form-inline sub-mr-2 sub-mt-2">
    <b-form-select v-model="model">
      <b-form-select-option value='gpt-3.5-turbo-0301'>GPT-3.5 Turbo 0301</b-form-select-option>
      <b-form-select-option value='gpt-4'>GPT-4.0</b-form-select-option>
    </b-form-select>
    <b-button variant="primary" @click="go">Ask</b-button>
    <b-spinner v-show="waiting"/>
  </div>
  <pre class="m-2">{{ response }}
  </pre>
</div>
</template>

<script>
import { app } from "@/services/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

export default {
  data() {
    return {
      input: "Where would a cat hide?",
      response: "",
      waiting: false,
      model: "gpt-3.5-turbo-0301"
    }
  },
  methods: {
    async go() {
      this.waiting = true;
      let addNote = httpsCallable(getFunctions(app), "notes-addNote");
      let res = await addNote({text:this.input, model:this.model});
      this.response = res.data.text;
      this.waiting = false;
    },
    setModel(model) {
      this.model = model;
    }
  }
}
</script>

<style>
pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
</style>
